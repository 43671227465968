import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import FlagsImg from "../../components/images/advertising/flags"

export default function Flags() {
    return (
        <>
            <Layout>
                <SEO title="Flags" />
                <Display
                    title="Flags"
                    description="Offering a number of different sizes and qunatities, flags and pennants are a fun, effective ways to boost awareness and help you get noticed."
                    prev="/advertising/tents"
                    next="/advertising/signs"
                    image={<FlagsImg />}
                />
            </Layout>                
        </>
    )
}
